.logo {
    display: inline-block;
    height: 50px;
    align-self: flex-start;
}

.title {
    display: inline-block;
    margin: 0px;
    height: 100%;
}

.profileIcon {
    display: inline-block;
    height: 50px;
    align-self: flex-end;
}

.Header {
    position: sticky;
    z-index: 1000;
    top: 0px;
    display: flex;
    background-color:bisque;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    min-height: 4vh;
}